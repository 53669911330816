//importing main links
import React from "react";
import { Link } from 'react-router-dom';
import { footerVariants } from '../utils/motion';
import { motion } from "framer-motion";

const URL = process.env.REACT_APP_LAYERLABS_URL;

function validateURL(url) {
    const parsed = new URL(url)
    return ['https:', 'http:'].includes(parsed.protocol)
}

const Footer = () => {
    return (
          //FOOTER COMPONENT--------------//

                <motion.footer
                variants={footerVariants}
                initial="hidden"
                whileInView="show"
                >
                <div className='footer font-latto font-semibold pt-4'>
                    <div className='container-fluid mx-4 py-5'>
                        <div className='row py-5 mb-5'>
                            <div className='col-12 col-lg-4 py-5'>
                                <div className='row brand-logo'>
                                    {/* <img src='/assets/images/logo2.png' alt='Logo' height='60' width='61' /> */}
                                    <h4 className='font text-[24px] md:text-[32px] text-[#627F92] px-3 pt-3 ml-2'>Shirleys Art Studio</h4>
                                </div>
                            </div>
                            <div className='col-6 col-lg-2 pt-5'>
                                <h5 className='font-latto font-semibold text-[#627F92] pb-3'>Site</h5>
                                <ul className='list-unstyled text-[#627f92ac]'>
                                    <li><Link className='link' to='/'>Home</Link></li>
                                    <li><Link className='link' to='/about'>About</Link></li>
                                    <li><Link className='link' to='/contact'>Contact</Link></li>
                                </ul>
                            </div>
                            <div className='col-6 col-lg-2 pt-5'>
                                <h5 className='font-latto font-semibold text-[#627F92] pb-3'>Gallery</h5>
                                <ul className='list-unstyled text-[#627f92ac]'>
                                    <li><Link className='link' to='/gallery'>All</Link></li>
                                    <li><Link className='link' to='/gallery/landscape'>Landscape</Link></li>
                                    <li><Link className='link' to='/gallery/seascape'>Seascape</Link></li>
                                    <li><Link className='link' to='/gallery/floral'>Floral</Link></li>
                                </ul>
                            </div>
                            <div className='col-6 col-lg-2 pt-5'>
                                <h5 className='font-latto font-semibold text-[#627F92] pb-3'>Paintings</h5>
                                <ul className='list-unstyled text-[#627f92ac]'>
                                    <li><Link className='link' to='/gallery/available'>Available Works</Link></li>
                                    <li><Link className='link' to='/gallery/sold'>Sold Works</Link></li>
                                </ul>
                            </div>
                            <div className='col-6 col-lg-2 align-items-center pt-5'>
                                <h5 className='font-latto font-semibold text-[#627F92] pb-3'>Secure Payments</h5>
                                <div className="row">
                                    <div className="col-6 col-md-4">
                                        <img className="img-fluid" src='/assets/images/stripe.png' width='50' height='50' alt='stripe logo' />
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <img className="img-fluid" src='/assets/images/visa.png' width='50' height='50' alt='stripe logo' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center layerlabs pb-4">
                            <a href={URL}>
                                <div className="flex flex-row items-center">
                                    <img src="/assets/images/layers.png" width='30px' height='30px' alt="layerlabs logo" />
                                    <span className="gradient-text footer-link ml-2">Created & Designed by Layerlabs.io</span>
                                </div>
                            </a>
                        </div>
                        <hr></hr>
                        <div className='row py-4 text-[#627F92] footer-bottom'>
                            <div className='col-12 col-lg-6'>
                                <p>© 2023 Shirleys Art Studio. All rights reserved.</p>
                            </div>
                            <div className='col-4 col-md-2 col-lg-2'>
                                <Link className='link' to='/terms-and-conditions'>Terms & Conditions</Link>
                            </div>
                            <div className='col-4 col-md-2 col-lg-2'>
                                <Link className='link' to='/privacy-policy'>Privacy Policy</Link>
                            </div>
                            <div className='col-4 col-md-2 col-lg-2'>
                                <Link className='link' to='/sales-and-refunds'>Sales & Refunds</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.footer>
    );
}

export default Footer;