//importing main links
import React from 'react';
import { Link } from 'react-router-dom';
import { AvailableWorks } from '../functionalComponents/functionalComponents';
import Spline from '@splinetool/react-spline';
import { motion } from 'framer-motion';
import { footerVariants, fadeIn, staggerContainer} from '../utils/motion';
import styles from '../styles';

//----MAIN ABOUT PAGE
const About = (props) => {


    //about page header
    const AboutHeader = () => {
        return (
            <>
            <div className={`${styles.paddings} relative z-10`}>
                <motion.div
                    variants={staggerContainer}
                    initial='hidden'
                    whileInView='show'
                    viewport={{ once: 'false', amount: 0.25 }}
                    className={`${styles.innerWidth}mx-auto flex
                    lg:flex-row flex-col gap-8 justify-between items-center`}
                >
                    <motion.div
                        variants={fadeIn('right', 'tween', 0.25, 1)}
                        initial='hidden'
                        whileInView='show'
                        className='md:w-1/3 z-10'
                    >
                        <h1 className='font-serif text-white font-extrabold drop-shadow-lg text-[76px] md:text-[120px] py-5'>About the artist.</h1> 
                    </motion.div>
                    <motion.div
                        variants={fadeIn('right', 'spring', 0.25, 1)}
                        className={`flex-1 ${styles.flexCenter} md:w-2/3 z-0`}
                      >
                        <div className='bg-[#ffffff8b] rounded-lg p-4 md:p-5'>
                        <img src='assets/images/artist.jpg' className='md:w-[450px] md:h-[550px] rounded-md shadow-xl' />
                        </div>
                        {/* <img src='assets/images/artist.png' className='w-[100%] md:hidden object-fit scale-150' />
                        <div className='md:h-[600px] w-full'>   
                            <Spline className='md:w-[100%] md:h-[100%]' scene="https://prod.spline.design/FBAPe3jaL9CneoZR/scene.splinecode" />
                        </div> */}
                    </motion.div>
                </motion.div>
            </div>
            <div className={`${styles.xPaddings} relative z-10`}>
                <motion.div
                    variants={fadeIn('up', 'tween', 0.25, 1)}
                    initial='hidden'
                    whileInView='show'
                    className='text-center'
                >
                    <span className='fa fa-quote-left text-white fa-lg py-4'></span>
                    <p className='font-serif font-semibold text-white text-[18px] md:text-[20px] mb-12'>I am an Irish artist living and working from my studio in Kildare. I have always been passionate about art in all its forms and I take my inspiration from the natural beauty of the world that surrounds us. I enjoy painting a variety of subjects, particularly landscapes, seascapes and flowers. I try to create an interesting composition and bring it to life by capturing the effects of light and shade.</p>
                </motion.div>
            </div>
            </>
        );
    }

    return (
        <>
            <div className='bkg-dark pb-5'>
                <motion.div
                    variants={footerVariants}
                    initial='hidden'
                    whileInView='show'
                    viewport={{ once: 'false', amount: 0.25 }}
                >
                    <div className='container-fluid'>
                        {/* rendering about header*/}
                        <AboutHeader /> 
                       </div>
                </motion.div>
            </div>
            <div className=''>
            <div className={`${styles.paddings} relative z-10`}>
                <motion.div
                    variants={staggerContainer}
                    initial='hidden'
                    whileInView='show'
                    viewport={{ once: 'false', amount: 0.25 }}
                    className={`${styles.innerWidth} ${styles.yPaddings} mx-auto flex
                    lg:flex-row flex-col gap-8`}
                >
                    <motion.div
                        variants={fadeIn('right')}
                        className={`flex-1 ${styles.flexCenter}`}
                    >
                        <img src='assets/images/art-img.JPG' className='w-[100%] h-[100%] shadow-2xl shadow-[#0000008f] ' />
                    </motion.div>
                    <motion.div
                        variants={fadeIn('left', 'tween', 0.25, 1)}
                        initial='hidden'
                        whileInView='show'
                        className='flex flex-col items-center lg:w-1/2'
                    >
                        <span className='fa fa-quote-left text-white fa-lg py-4'></span>
                        <p className='font-two text-[#627F92] md:text-[18px] grey-text p-4'>My paintings are inspired by the rugged landscapes of Ireland and the vibrant colours that emanate from its natural beauty throughout the seasons. I strive to visually capture and record special moments, people and places in time and hope that the viewer will engage and connect emotionally with the images that emerge on the canvas. I work mostly in acrylics using bold bright colours to create a sense of light and atmosphere in my paintings. I have exhibited my work in a number of venues in Kildare and Dublin. If you have something of personal interest which you would like to see on canvas I am happy to accept commisions.</p>
                    </motion.div>
                </motion.div>
            </div>
            </div>
            <div className='bkg-dark'>
                <div className={`${styles.paddings} relative z-10`}>
                    <motion.div
                        variants={staggerContainer}
                        initial='hidden'
                        whileInView='show'
                        viewport={{ once: 'false', amount: 0.25 }}
                        className={`${styles.innerWidth} mx-auto flex flex-col`}
                    >
                        <motion.div
                            variants={fadeIn('left', 'tween', 0.25, 1)}
                            initial='hidden'
                            whileInView='show'
                            className='text-center pb-5'
                        >
                        <h1 className='font-serif text-white italic font-extrabold tracking-wider text-[36px] md:text-[52px] py-5'><span className=''>Available Works</span></h1>
                            <h4 className='font-serif text-white uppercase text-[22px] md:text-[32px]'>Check out my range of available works</h4>
                        </motion.div>
                        <div className='container'>
                            <div className='row'>
                                {/* rendering available works */}
                                <AvailableWorks
                                item={props.available1} 
                                isLoading={props.isLoading} 
                                errMsg={props.errMsg}
                                />
                                <AvailableWorks 
                                item={props.available2} 
                                isLoading={props.isLoading} 
                                errMsg={props.errMsg}
                                />
                                <AvailableWorks 
                                item={props.available3} 
                                isLoading={props.isLoading} 
                                errMsg={props.errMsg}
                                />
                            </div>
                        </div>
                        <div className='row py-5'>
                            <div className='col-12 text-center'>
                                <Link to='/gallery/available' className='btn border-[2px] border-white hover:shadow-lg font-lato font-semibold text-white py-2 px-4 my-6'>View More<span className='fa fa-chevron-right ml-2'></span></Link>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </>
    );
    
}

export default About;
