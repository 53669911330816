import React from 'react';

const SalesAndRefunds = (props) => {
    return (
        <>
            <div className='bkg-dark py-5'>
                <div className="container mb-5 scroll-pt-52 text-white text-latto font-semibold px-5">
                    <h1 className="text-center large-text-two py-5">Sales and Refunds Policy</h1>
                    <h2 className="text-[22px] py-5">General Information</h2>
                    <p>All orders are subject to product availability. If an item is not in stock at the time you place your order, we will notify you and refund you the total amount of your order, using the original method of payment. </p>
                    <h2 className="text-[22px] py-5">Delivery Location</h2>
                    <p>Items offered on this website are only available for delivery to addresses in Europe, United Kingdom, United States and Canada. Any shipments outside of Europe, United Kingdom, United States and Canada are not available at this time.</p>
                    <h2 className="text-[22px] py-5">Delivery Time</h2>
                    <p>An estimated delivery time will be provided to you once your order is placed. Delivery times are estimates and commence from the date of shipping, rather than the date of order. Delivery times are to be used as a guide only and are subject to the acceptance and approval of your order.</p>
                    <p className="py-5">Unless there are exceptional circumstances, we make every effort to fulfill your order within [14] business days of the date of your order. Business days mean Monday to Friday, except holidays.</p>
                    <p>Please note we do not ship on [Sundays].</p>
                    <p>Date of delivery may vary due to carrier shipping practices, delivery location, method of delivery, and the items ordered.</p>
                    <h2 className="text-[22px] py-5">Returns</h2>
                    <p>We accept returns in the case all information is provided as to the return of the item. You can return unopened items in the original packaging within 14 days of your purchase with receipt or proof of purchase. If 14 days or more have passed since your purchase, we cannot offer you a refund or an exchange.</p>
                    <p className="py-5">Upon receipt of the returned item, we will fully examine it and notify you via email, within a reasonable period of time, whether you are entitled to a return. If you are entitled to a return, we will refund your purchase price and a credit will automatically be applied to your original method of payment.</p>
                    <p>To follow-up on the status of your return, please contact us at: shirleyartiststudio@gmail.com.</p>
                    <h2 className="text-[22px] py-5">Shipping</h2>
                    <p>Refunds do not include any shipping and handling charges shown on the packaging slip or invoice. Shipping charges for all returns must be prepaid and insured by you. You are responsible for any loss or damage to hardware during shipment. We do not guarantee that we will receive your returned item. Shipping and handling charges are not refundable. Any amounts refunded will not include the cost of shipping.</p>
                    <p className="py-3">To return the the item you purchased get in please mail us at shirleyartiststudio@gmail.com for shipping address information.</p>
                    <p className="py-5">This document was last updated on June 24, 2022</p>
                </div>
            </div>
        </>
    );
}

export default SalesAndRefunds;