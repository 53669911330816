//importing main links
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { AddToCart, RenderPaintings } from '../functionalComponents/functionalComponents';
import { Loading } from './LoadingComponent';


const Landscape = (props, index) => {

    const landscapePaintings = props.paintings.map((painting, index) => {
        if(painting.type === 'landscape' && painting.isAvailable === true) {
            return (
                <div key={painting.id.toString()} className='col-12 col-md-6 col-lg-4 text-center py-3'>
                    <RenderPaintings item={painting} />
                    <AddToCart addToCart={props.addToCart} item={painting} />
                </div>
            );
        }
        else if(painting.type === 'landscape' && painting.isAvailable === false) {
            return (
                <div key={painting.id.toString()}  className='col-12 col-md-6 col-lg-4 text-center py-3'>
                    <RenderPaintings item={painting} />
                    <button className='bg-[#627f9294] btn-block rounded-xl text-white font-latto font-semibold px-4 py-2 my-3'>Sold</button>
                </div>
            );
        }
        else {
            return (
                <React.Fragment key={index}></React.Fragment>
            );
        }
    });

    if(props.isLoading) {
        return (
            <div className='container'>
                <div className='row'>
                    <Loading />
                </div>
            </div>
        );
    }
    else if(props.errMsg) {
        return(
            <div className='row ml-auto px-4'>
                <div className='col text-center'>
                    <div className='error-card'>
                        <img src='/assets/images/error.png' width='90' height='90' alt='error message' />
                        <h4 className='font-two'>{props.errMsg}</h4>
                        <h6 className='font-two py-5'>Sorry something went wrong!</h6>
                    </div>
                </div>
            </div>
        );
    }
    else
 
    return (
        <>
            <div className='bkg-dark pb-5'>
                <div className='bg-white container rounded-xl mb-5'>
                    <div className='row d-flex justify-content-center pt-5'>
                        <div className='col-12 text-center'>
                        <h5 className='font-serif text-[#627F92] text-[24px]'>Choose from...</h5>
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center text-center py-5 mx-2'>
                        <div className='col-12 col-md-3 bg-[#39505e] border-[1px] border-none shadow-md rounded-xl hover:shadow-lg py-2 my-2'>
                            <Link to='/gallery/landscape' className='btn'>
                                <span className='font-latto font-semibold text-white text-[22px]'>LANDSCAPE</span>
                            </Link>
                        </div>
                        <div className='col-12 col-md-3 bg-[#627F92] border-[1px] border-none shadow-md rounded-xl hover:shadow-lg py-2 my-2 mx-2'>
                            <Link to='/gallery/seascape' className='btn'>
                                <span className='font-latto font-semibold text-white text-[22px]'>SEASCAPE</span>
                            </Link>
                        </div>
                        <div className='col-12 col-md-3 bg-[#627F92] border-[1px] border-none shadow-md rounded-xl hover:shadow-lg py-2 my-2'>
                            <Link to='/gallery/floral' className='btn'>
                                <span className='font-latto font-semibold text-white text-[22px]'>FLORAL</span>
                            </Link>
                        </div>
                    </div>
                    <div className='container tabs pt-5'>
                        <div className='row'>
                            {/* rendering landscape paintings */}
                            {landscapePaintings}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    
}

export default Landscape;