//importing main links
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Loading } from '../components/LoadingComponent';
import { useSnackbar } from 'notistack';
import { FadeTransform } from 'react-animation-components';
import { Button } from 'reactstrap';
import ScrollTop from '../functionalComponents/ScrollTop';
import { fadeIn, staggerContainer } from '../utils/motion';
import { motion } from 'framer-motion';
import styles from '../styles';



const FLATICON_URL = process.env.REACT_APP_FLATICON_URL;

//----functional components to reuse
function validateURL(url) {
    const parsed = new URL(url)
    return ['https:', 'http:'].includes(parsed.protocol)
}
//render contact section
export const RenderContact = () => {
    return (
        <>
            <div className={`${styles.paddings} relative z-10`}>
                <motion.div
                variants={staggerContainer}
                initial='hidden'
                whileInView='show'
                viewport={{ once: 'false', amount: 0.25 }}
                className={`${styles.innerWidth} mx-auto flex
                lg:flex-row items-center flex-col justify-center text-center gap-8`}
                >
                    <motion.div
                        variants={fadeIn('right', 'tween', 0.25, 1)}
                        initial='hidden'
                        whileInView='show'
                    >
                        <h1 className='large-text text-white font-serif font-extrabold'>Interested in my paintings?</h1>
                        <Link to='/contact' className='btn border-[2px] border-white hover:shadow-lg font-lato font-semibold text-white py-2 px-4 mt-12'>Get in touch<span className='fa fa-chevron-right ml-2'></span></Link>
                    </motion.div>
                    {/* <div className='col-12 col-md-6 small-screen text-center py-5'>
                        <a href={FLATICON_URL}>
                            <img src='/assets/images/email.png' className='img-fluid' alt='mail' />
                        </a>
                    </div> */}
                </motion.div>
            </div>
        </>
   
    );
}

export const RenderRequest = () => {
    return (
        <div className={`${styles.paddings} relative z-10`}>
            <motion.div
                variants={staggerContainer}
                initial='hidden'
                whileInView='show'
                viewport={{ once: 'false', amount: 0.25 }}
                className={`${styles.innerWidth} mx-auto flex
                lg:flex-row items-center flex-col justify-center text-center gap-8 mt-[20px]`}
            >
                <motion.div
                    variants={fadeIn('right', 'tween', 0.25, 1)}
                    initial='hidden'
                    whileInView='show'
                >
                    <h1 className='font-serif font-extrabold text-white large-text pb-3'>Do you have a personal<br/> request?</h1>
                    <Link to='/contact' className='btn border-[2px] border-white hover:shadow-lg font-lato font-semibold text-white py-2 px-4 mt-12'>Get in touch<span className='fa fa-chevron-right ml-2'></span></Link>
                </motion.div>
{/* 
                <div className='col-12 col-md-4 small-screen text-center py-5'>
                    <a href={FLATICON_URL}>
                        <img src='/assets/images/email.png' className='img-fluid' alt='mail' />
                    </a>
                </div> */}
            </motion.div>
        </div>
    );
}

export const RenderPaintings = ({item, index}) => {
    return (
        <>  
            <FadeTransform key={index}  in
                transformProps={{
                exitTransform: 'scale(0.3) translateY(-20%)'
            }}>
                <Link to={`/gallery/${item.id}`}>
                    <div className='img-container'>
                        <img src={item.image} className='img-painting rounded-md w-[100%] h-[400px]' alt={item.title} />
                    {/* <Button type='submit' className='btn-light font-poppins px-4 py-2'>VIEW</Button> */}
                    </div>
                </Link>
            </FadeTransform>
        </>
    );
}

//render available works section
export const AvailableWorks = ({item, isLoading, errMsg, index}) => {
    if(isLoading) {
        return (
            <Loading key={index} />
        );
    }
    else if(errMsg) {
        return (
            <div key={index} className='row ml-auto px-4'>
                <div className='col text-center'>
                    <div className='error-card'>
                        <img src='/assets/images/error.png' width='90' height='90' alt='error message' />
                        <h4 className='font-two'>{errMsg}</h4>
                        <h6 className='font-two py-5'>Sorry something went wrong!</h6>
                    </div>
                </div>
            </div>
        );
    }
    return (
        
        <div className='col-12 col-md-4 col-lg-4 py-4'>
            <motion.div 
                key={item.id}
                variants={fadeIn('up', 'tween', index * 0.5, 0.75)}
                initial='hidden'
                whileInView='show'
                >
            <Link to='/gallery/available'>
                <img src={item.image} className='img-painting rounded-md w-[100%] h-[400px]'  alt={item.title} />
            </Link>
            </motion.div>
        </div>
    );
}

export const HomeFeatured = ({item, isLoading, errMsg, index}) => {

    if(isLoading) {
        return (
            <Loading key={index} />
        );
    }
    else if(errMsg) {
        return (
            <div key={index} className='row ml-auto px-4'>
                <div className='col text-center'>
                    <div className='error-card'>
                        <img src='/assets/images/error.png' width='90' height='90' alt='error message' />
                        <h4 className='font-two'>{errMsg}</h4>
                        <h6 className='font-two py-5'>Sorry something went wrong!</h6>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            <div key={item.id.toString()} className='col-12 col-md-6 col-lg-4 py-4'>
                    <motion.div 
                      key={item.id}
                      variants={fadeIn('up', 'tween', index * 0.5, 0.75)}
                      initial='hidden'
                      whileInView='show'

                    >
                    <Link key={item.id} to={`/gallery/${item.id}`}>
                        <div className='img-container'>
                            <img src={item.image} className='rounded-md w-[100%] h-[400px]' alt={item.title} />
                            <div className=' border-[1px] border-t-0 border-[#cacacab7] rounded-b-md p-4'>
                                <h1 className='font-serif text-white font-semibold'>{item.title}</h1>
                            </div>
                        </div>
                    </Link>
                </motion.div>
            </div>
        </>
    );
}

export class AddToCart extends Component {

    render() {

        const AddItemToCart = ({item, index}) => {

            const { enqueueSnackbar } = useSnackbar();
            const handleClick = (e) => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                e.preventDefault();
                this.props.addToCart(item)
                enqueueSnackbar(<p className='font-two pt-3'>Item successfully added to cart!</p>, {
                    variant: 'info'
                });
            };

            return (
                <ScrollTop key={index}>
                    <div>
                        <button onClick={handleClick} className='btn bg-[#39505e] hover:opacity-80 shadow-lg btn-block rounded-xl text-white font-latto font-semibold px-4 py-2 my-3'><span className='fa fa-plus mr-2'></span>Add to cart</button>
                    </div>
                </ScrollTop>
            );

        }

        return (
            <>
            <AddItemToCart item={this.props.item} addToCart={this.props.addToCart} />
            </>
        )
    }
}

export const Proccessing = () => {
    return (
        <span className="proccessing"></span>
    );
}
