//importing main links
import React from 'react';
import { Fade } from 'react-animation-components';
import { Link } from 'react-router-dom';
import { RenderContact } from '../functionalComponents/functionalComponents'
import { HomeFeatured, AvailableWorks } from '../functionalComponents/functionalComponents';
import Spline from '@splinetool/react-spline';
import { motion } from 'framer-motion';
import { staggerContainer, fadeIn, footerVariants } from '../utils/motion';

import styles from '../styles';
//home page component

/* HOMEPAGE HEADER */
const HomeHeader = () => {
    return (
        <>
            <div className={`${styles.xPaddings} relative z-10`}>
                <motion.div
                    variants={staggerContainer}
                    initial='hidden'
                    whileInView='show'
                    viewport={{ once: 'false', amount: 0.25 }}
                    className={`${styles.innerWidth} ${styles.yPaddings} mx-auto flex
                    lg:flex-row flex-col gap-8`}
                >
                    <motion.div
                        variants={fadeIn('right', 'tween', 0.25, 1)}
                        initial='hidden'
                        whileInView='show'
                        className='md:w-1/2 p-2 z-10'
                    >
                        <h1 className='font-serif font-bold text-white text-[76px] md:text-[120px] drop-shadow-lg pb-4'>Shirley's <span className='italic'>Art</span> <br/>Studio</h1>
                        <h2 className='font-serif font-semibold text-white text-[22px] md:text-[28px] py-4'>Jump right in and explore my collection of paintings.</h2>
                            {/* <h4 className='font-two large-text'>Browse, shop and feel free to share my work with others!</h4> */}
                        <Link className='btn border-white border-[2px] hover:shadow-lg text-white font-lato font-semibold py-2 px-4 my-4' to='/gallery'>All Works<span className='fa fa-chevron-right ml-2'></span></Link>
                    </motion.div>
                    <motion.div
                        variants={fadeIn('right', 'spring', 0.25, 1)}
                        className={`flex-1 ${styles.flexCenter} md:w-1/2 z-0`}
                      >
                        {/* <img src='assets/images/homepage.png' className='w-[100%] scale-150 md:hidden object-contain z-0' />
                        <div className='md:h-[600px] w-full'>
                            <Spline className='md:w-[100%] md:h-[100%]' scene="https://prod.spline.design/4waMggEuTAV9zE7v/scene.splinecode" />
                        </div> */}
                        <img src='assets/images/art-img.JPG' className='w-[100%] shadow-2xl shadow-[#0000008f] ' />
                    </motion.div>
                </motion.div>
               
            </div>
                <div className={`${styles.xPaddings} flex flex-row flex-wrap justify-center`}>
                    <a href='https://instagram.com/shirleyhackett47' className='btn border-[2px] border-white rounded-xl hover:shadow-lg px-12 py-3 mx-6 my-4'>
                        <div className='flex flex-row items-center'>
                            <span className='font-latto font-semibold text-white'>Instagram</span>
                            <img src='assets/images/instagram.png' className='ml-2 w-[30px] h-[30px]' />
                        </div>
                    </a>
                    <a href='https://www.facebook.com/shirley.hackett.7' className='btn border-[2px] border-white rounded-xl hover:shadow-lg px-12 py-3 mx-6 my-4'>
                        <div className='flex flex-row items-center'>
                            <span className='font-latto font-semibold text-white'>Facebook</span>
                            <img src='assets/images/fb.svg' className='ml-2 w-[30px] h-[30px]' />
                        </div>
                    </a>
                    <Link to='/contact'className='btn border-[2px] border-white rounded-xl hover:shadow-lg px-12 py-3 mx-6 my-4'>
                        <div className='flex flex-row items-center'>
                            <span className='font-latto font-semibold text-white'>Email</span>
                            <img src='assets/images/message.svg' className='ml-2 w-[30px] h-[30px]' />
                        </div>
                    </Link>
                </div>
        
        </>
    );
}


const Home = (props) => {

    return (
        <> 
            <motion.div
                variants={staggerContainer}
                initial='hidden'
                whileInView='show'
                viewport={{ once: 'false', amount: 0.25 }}
                className={`mx-auto flex flex-col gap-8`}
            >
                <motion.div
         
                    initial='hidden'
                    whileInView='show'
                    viewport={{ once: 'false', amount: 0.25 }}
                >
                    <div className='bkg-dark pb-5'>
                        <div className='container-fluid'>
                        {/* rendering home page header */}
                            <HomeHeader />
                        </div>
                    </div>
                </motion.div>
            </motion.div>
            <div className='bkg-dark pb-12'>
            <div className={`${styles.xPaddings} relative z-10`}>
                <motion.div
                    variants={staggerContainer}
                    initial='hidden'
                    whileInView='show'
                    viewport={{ once: 'false', amount: 0.25 }}
                    className={`${styles.innerWidth} mx-auto flex flex-col`}
                >
                    <motion.div
                        variants={fadeIn('left', 'tween', 0.25, 1)}
                        initial='hidden'
                        whileInView='show'
                        className='text-center'
                    >
                        <h1 className='font-serif text-white italic font-extrabold tracking-wider text-[44px] md:text-[52px] py-5'>Featured Paintings</h1>
                    </motion.div>
                    {/* rendering homepage featured */}
                    <div className=' rounded-xl py-10'>
                    <div className='container'>
                        <div className='row'>
                            <HomeFeatured 
                            item={props.painting1} 
                            isLoading={props.isLoading}
                            errMsg={props.errMsg}
                            />
                            <HomeFeatured 
                            item={props.painting2}
                            isLoading={props.isLoading} 
                            errMsg={props.errMsg}
                            />
                            <HomeFeatured 
                            item={props.painting3}
                            isLoading={props.isLoading} 
                            errMsg={props.errMsg}
                            />
                            <HomeFeatured 
                            item={props.painting4} 
                            isLoading={props.isLoading}
                            errMsg={props.errMsg}
                            />
                            <HomeFeatured 
                            item={props.painting5} 
                            isLoading={props.isLoading}
                            errMsg={props.errMsg}
                            />
                            <HomeFeatured 
                            item={props.painting6} 
                            isLoading={props.isLoading}
                            errMsg={props.errMsg}
                            />
                            <div className='col-12 text-center py-4'>
                                <Link to='/gallery' className='border-[2px] border-white hover:shadow-lg font-latto font-semibold text-white hover:text-[#dabdb5] rounded-lg py-2 px-6 my-4'>View All<span className='fa fa-chevron-right ml-2'></span></Link>
                            </div>
                        </div>
                    </div>
                    </div>
                </motion.div>
            </div>
            </div>
            <div className='bkg-dark'>
            <div className={`${styles.paddings} relative z-10`}>
                <motion.div
                    variants={staggerContainer}
                    initial='hidden'
                    whileInView='show'
                    viewport={{ once: 'false', amount: 0.25 }}
                    className={`${styles.innerWidth} mx-auto flex flex-col bg-white rounded-xl`}
                >
                    <motion.div
                        variants={fadeIn('left', 'tween', 0.25, 1)}
                        initial='hidden'
                        whileInView='show'
                        className='text-center pb-5'
                    >
                        <h1 className='font-serif text-[#627F92] italic font-extrabold tracking-wider text-[36px] md:text-[52px] py-5'><span className=''>Available Works</span></h1>
                        <h4 className='font-serif text-[#627F92] font-2 uppercase text-[22px] md:text-[28px]'>Check out my range of available works</h4>
                    </motion.div>
                    {/* rendering homepage available */}
                    <div className={`${styles.xPaddings} relative z-10`}>
                        <div className='row'>
                            <AvailableWorks 
                                item={props.available1}
                                isLoading={props.isLoading}
                                errMsg={props.errMsg}
                            />
                            <AvailableWorks 
                                item={props.available2}
                                isLoading={props.isLoading}
                                errMsg={props.errMsg}
                            />
                            <AvailableWorks 
                                item={props.available3}
                                isLoading={props.isLoading}
                                errMsg={props.errMsg}
                            />
                        </div>
                    </div>
                    <div className='col-12 text-center py-5'>
                        <Link to='/gallery/available' className='btn border-[2px] border-[#627F92] text-[#627F92] hover:shadow-lg font-lato font-semibold py-2 px-4 my-4'>View More<span className='fa fa-chevron-right ml-2'></span></Link>
                    </div>
                </motion.div>
            </div>
            </div>
            <motion.div
                variants={staggerContainer}
                initial='hidden'
                whileInView='show'
                viewport={{ once: 'false', amount: 0.25 }}
                className={`mx-auto flex flex-col`}
            >
                <motion.div
                    variants={footerVariants}
                    initial='hidden'
                    whileInView='show'
                    viewport={{ once: 'false', amount: 0.25 }}
                >
                    <div className='bkg-dark'>
                        {/* rendering contact */}
                        <RenderContact />
                    </div>
                </motion.div>
            </motion.div>
        </>
    );
}
    


export default Home;