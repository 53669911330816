//importing main links
import React from 'react';
import { CardBody} from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import { FadeTransform } from 'react-animation-components';
import { Loading } from './LoadingComponent';
import { RenderRequest, AddToCart } from '../functionalComponents/functionalComponents';
import { motion } from 'framer-motion';
import { fadeIn, footerVariants } from '../utils/motion';
import styles from '../styles';

const RenderPainting = ({item, addToCart, index}) => {
    if(item.price === 0 || item.isAvailable === false) {
        return (
            <> <div className='container-fluid py-5 mt-3' key={item.id.toString()}>
                    <div className='row flex items-center'>
                        <div className='col-12 col-md-6'>
                            <FadeTransform in
                            transformProps={{
                            exitTransform: 'scale(0.3) translateY(-20%)'
                            }}>
                                <img src={item.image} className='img-painting-detail' width='100%' height='420px' alt='sold painting' />
                                <p className='font artist-text pt-4'><i>{item.artist}</i></p>
                            </FadeTransform>
                        </div>
                        <div className='col-12 col-md-6'>
                            <div className=' text-center'>
                                <motion.div
                                    variants={fadeIn('left')}
                                    initial='hidden'
                                    whileInView='show'
                                >
                                    <CardBody className='py-5'>
                                        <h2 className='font-latto font-semibold text-[24px]'>{item.title}</h2>
                                        <div className='col-12 bg-[#39505e] rounded-xl my-4 py-4'>
                                        <h3 className='text-[26px] font-latto text-white'><b>Sold</b></h3>
                                        </div>
                                        <h6 className='font-latto text-[22px]'>{item.description}</h6>
                                    </CardBody>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    else {
        return (
            <> <div key={index} className='container-fluid py-5 mt-3'>
                    <div className='row flex items-center'>
                        <div className='col-12 col-md-6'>
                            <FadeTransform in
                            transformProps={{
                            exitTransform: 'scale(0.3) translateY(-20%)'
                            }}>
                                <img src={item.image} className='img-painting' width='100%' height='420px' alt='available painting' />
                                <p className='font artist-text pt-4'><i>{item.artist}</i></p>
                            </FadeTransform>
                        </div>
                        <div className='col-12 col-md-6'>
                            <div className='font-latto text-center'>
                                <motion.div
                                    variants={fadeIn('left')}
                                    initial='hidden'
                                    whileInView='show'
                                >
                                    <CardBody className='py-5'>
                                        <h2 className='text-[24px] font-semibold'>{item.title}</h2>
                                        <h1 className='py-4 text-[22px]'>€{item.price}</h1>
                                        <h6 className='text-[22px]'>{item.description}</h6>
                                        <p className='text-[18px] pt-4'>Paintings come signed by the artist.</p>
                                        <p>Frames are not included.</p>
                                        <div className='row d-flex justify-content-center my-4'>
                                        <NavLink to={`/checkout/${item.id}`} className='btn bg-primary shadow-md shadow-[#2d98efd9] font-latto font-semibold text-white rounded-xl my-3 px-4 mx-2'>Purchase</NavLink>
                                        <AddToCart item={item} addToCart={addToCart} />
                                        </div>
                                    </CardBody>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


const PaintingDetail = (props) => {

    if(props.errMsg) {
        return(
            <div className='container py-5'>
                <div className='row d-flex justify-content-center py-5'>
                    <div className='col-12 col-md-8'>
                        <div className='error-card'>
                            <div className='col-12 text-center'>
                                <img src='/assets/images/error.png' width='220' height='220' alt='error message' />
                                <h4 className='font-two'>{props.errMsg}</h4>
                                <h2 className='font-two py-5'>Sorry something went wrong!</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if(props.isLoading) {
        return (
            <div className='container'>
                <div className='row'>
                    <Loading />
                </div>
            </div>
        );
    } else {
   
        return (
            <>
                <div className='bkg-dark'>
                    <div className='container'>
                        <div className='row pt-3'>
                            <div className='mt-5 pb-4'>
                                <Link className='breadcrumb-link text-white' to='/gallery'>
                                    <span className='fa fa-chevron-left b-link text-white mr-2'></span> <span className='b-link font-latto font-semibold text-white'>Back to Gallery</span>
                                </Link>
                            </div>
                        </div>
                        <div className='row pb-5'>
                            {/* rendering painting detail */}
                            <div className='bg-white rounded-xl'>
                            <div className={`${styles.paddings} relative z-10`}>
                            <RenderPainting items={props.cartItem} item={props.painting} addToCart={props.addToCart} />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bkg-dark'>
                    <motion.div
                        variants={footerVariants}
                        initial='hidden'
                        whileInView='show'
                        viewport={{ once: 'false', amount: 0.25 }}
                    >
                        <div className='container pt-4'>
                            <RenderRequest />
                        </div>
                    </motion.div>
                </div>

            </>
        );
    }
}

export default PaintingDetail;