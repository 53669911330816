//importing main links
import React from 'react';
import { RenderPaintings } from '../functionalComponents/functionalComponents';
import { Link } from 'react-router-dom';
import { Loading } from './LoadingComponent';
import styles from '../styles';


const Sold = (props) => {

    const soldPaintings = props.paintings.map((painting, index) => {
        if(painting.isAvailable === false) {
            return (
                <div key={painting.id.toString()} className='col-12 col-md-6 col-lg-4 text-center py-3'>
                    <RenderPaintings item={painting} />
                    <button className='bg-[#627f9294] btn-block rounded-xl text-white font-latto font-semibold px-4 py-2 my-3'>Sold</button>
                </div>
            );
        } 
        else {
            return (
                <React.Fragment key={index}></React.Fragment>
            );
        }
    });

    if(props.isLoading) {
        return (
            <div className='container'>
                <div className='row'>
                    <Loading />
                </div>
            </div>
        );
    }
    else if(props.errMsg) {
        return(
            <div className='container py-5'>
                <div className='row d-flex justify-content-center py-5'>
                    <div className='col-12 col-md-8'>
                        <div className='error-card'>
                            <div className='col-12 text-center'>
                                <img src='/assets/images/error.png' width='220' height='220' alt='error message' />
                                <h4 className='font-two'>{props.errMsg}</h4>
                                <h2 className='font-two py-5'>Sorry something went wrong!</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else

    return (
        <>
            <div className='bkg-dark pb-12' key={props.paintings.id}>
                <div className='container'>
                    <div className='pt-5 pb-4'>
                        <Link className='breadcrumb-link text-white' to='/gallery'>
                            <span className='fa fa-chevron-left b-link text-white mr-2'></span> <span className='b-link font-latto font-semibold text-white'>Back to Gallery</span>
                        </Link>
                    </div>
                </div>
                <div className='container bg-white rounded-xl py-2'>
                    <div className={`${styles.paddings} relative z-10`}>
                    <div className='col-12 pt-5 py-4 text-center'>
                        <h1 className='font-serif font-semibold text-[#627F92] text-[34px] md:text-[42px] tracking-wide'>Sold Works</h1>
                    </div>
                    <div className='row mt-4'>
                        {/* rendering available paintings */}
                        {soldPaintings}
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
    
}

export default Sold;