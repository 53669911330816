import React from 'react';
import { FadeTransform } from 'react-animation-components';
import { motion } from 'framer-motion';
import { footerVariants } from '../utils/motion';


const PaymentSuccess = (props) => {

    return (
        <>
            <motion.div
                variants={footerVariants}
                initial='hidden'
                whileInView='show'
                viewport={{ once: 'false', amount: 0.25 }}
            >
                <div className='checkout-container bkg-dark'>
                    <div className='container py-5'>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-12 col-md-8 text-center'>
                                <FadeTransform in
                                transformProps={{
                                exitTransform: 'scale(0.3) translateY(-20%)'
                                }}>
                                <div className='border-[1px] rounded-lg shadow-lg py-4 mb-5'>
                                    <div className='row align-items-center text-center py-5'>
                                        <div className='col-12'>
                                            <h2 className='font-serif font-extrabold large-text text-white py-4 mb-5'>Payment Successful!</h2>
                                        </div>
                                        <div className='col-12 flex justify-center py-4'>
                                            <img src='/assets/images/check.svg' width='190' height='190' alt='success' />
                                        </div>
                                        <div className='col-12 py-5'>
                                            <h4 className='font-serif font-semi-bold text-white text-[22px]'>Thank you for shopping your item is on it's way!</h4>
                                        </div>
                                    </div>
                                </div>
                                </FadeTransform>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    );
}

export default PaymentSuccess;