export const ADD_PAINTINGS = 'ADD_PAINTINGS';
export const PAINTINGS_LOADING = 'PAINTINGS_LOADING';
export const PAINTINGS_FAILED = 'PAINTINGS_FAILED';

export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';



