export const COUNTRIES = 
[{
    name: 'Andorra',
    code: "AD",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "Albania",
    code: "AL",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "Austria",
    code: "AT",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Bosnia and Herzegovina",
    code: "BA",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'

  }, {
    name: "Belgium",
    code: "BE",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  },
   {
    name: "Bulgaria",
    code: "BG",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Switzerland",
    code: "CH",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Cyprus",
    code: "CY",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Czech Republic",
    code: "CZ",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Germany",
    code: "DE",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Denmark",
    code: "DK",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Estonia",
    code: "EE",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Spain",
    code: "ES",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Finland",
    code: "FI",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Faroe Islands",
    code: "FO",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "France",
    code: "FR",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "United Kingdom",
    code: "GB",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Greece",
    code: "GR",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Croatia",
    code: "HR",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Hungary",
    code: "HU",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Ireland",
    code: "IE",
    shipping_small: 12,
    shipping_large: 13,
    location: 'IRL'
  }, {
    name: "Iceland",
    code: "IC",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "Italy",
    code: "IT",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  },  {
    name: "Lithuania",
    code: "LT",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Luxembourg",
    code: "LU",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Latvia",
    code: "LV",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Monaco",
    code: "MC",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "Moldova, Republic of",
    code: "MD",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "Malta",
    code: "MT",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Netherlands",
    code: "NL",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Norway",
    code: "NO",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "Poland",
    code: "PL",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Portugal",
    code: "PT",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Romania",
    code: "RO",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  },  {
    name: "Sweden",
    code: "SE",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "Slovenia",
    code: "SI",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "Slovakia",
    code: "SK",
    shipping_small: 17,
    shipping_large: 26,
    location: 'EU'
  }, {
    name: "San Marino",
    code: "SM",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "Ukraine",
    code: "UA",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "Holy See (Vatican City State)",
    code: "VA",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "United States",
    code: "US",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
  }, {
    name: "Canada",
    code: "CA",
    shipping_small: 25,
    shipping_large: 45,
    location: 'Worldwide'
}]