import React, { Component } from 'react';
import { Navbar, NavbarBrand, Nav, NavbarToggler, Collapse, NavItem,
    DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Cart from '../components/CartComponent' 
import { navVariants, fadeIn } from '../utils/motion';
import { motion } from 'framer-motion';
import { BsBag } from 'react-icons/bs';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
        };
        this.toggleNav = this.toggleNav.bind(this);
   
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }


    render() {

        const CartLogoAnimation = ({cartItems, removeFromCart, clearCart}) => {
            if(cartItems.length === 0) {
                return (
                    <NavItem className='flex justify-center py-2'>
                        <BsBag className='text-white text-xl font-semibold' />
                    </NavItem>
                );
            }
            else {
                return (
                    <UncontrolledDropdown className='' nav>
                        <DropdownToggle nav className='nav-text nav-cart-items font-two'>
                            <div className='row flex justify-center px-1'>
                                <BsBag className='text-white text-xl font-semibold' />
                                <span className='font-latto badge rounded-pill text-white text-[14px] move-img'>{cartItems.length}</span>
                            </div>
                        </DropdownToggle>
                            <DropdownMenu className='cart-drop-menu' top='true'>
                                <Cart cartItems={cartItems} removeFromCart={removeFromCart} clearCart={clearCart} />
                        </DropdownMenu>  
                    </UncontrolledDropdown>
                );
            }
        }

        return (
            <>  {/* website navbar */}
                <motion.nav
                    variants={navVariants}
                    initial="hidden"
                    whileInView="show"

                >
                    <Navbar light expand='lg' className='navbar'>
                        <div className='container-fluid text-center'>
                            <NavbarBrand href='/'>
                            <span className='font text-white pl-3 navbrand'>Shirleys Art Studio</span>
                            </NavbarBrand>
                            <NavbarToggler className='toggler'><img src={this.state.isNavOpen ? '/assets/images/close.svg' : '/assets/images/menu.svg' } className="navbar-toggle" onClick={this.toggleNav} /></NavbarToggler>
                            <Collapse isOpen={this.state.isNavOpen} className='py-3' navbar>
                                <Nav navbar className='ml-auto'>
                                    <NavItem>
                                        {!CartLogoAnimation ? (
                                        <NavLink className='nav-link mx-4 px-4 py-2' to='/home'>
                                            <span className='font-serif font-semibold text-white'>Home</span>
                                        </NavLink>
                                        ) : (
                                        <NavLink className='nav-link align-self-center mx-4 px-4 py-2 mb-2' to='/home'>
                                            <span className='font-serif font-semibold text-white'>Home</span>
                                        </NavLink>
                                        )}
                                    </NavItem>
                                    <UncontrolledDropdown nav className=''>
                                        {!CartLogoAnimation ? (
                                            <DropdownToggle nav className='font-serif font-semibold text-white py-2 mx-4 px-4'>Gallery<span className='fa fa-chevron-down ml-2 text-white'></span></DropdownToggle>
                                        ) : (
                                            <DropdownToggle nav className='font-serif font-semibold text-white mx-4 px-4 pt-2 pb-3 mb-1'>Gallery<span className='fa fa-chevron-down ml-2 text-white'></span></DropdownToggle>
                                        )}
                                        <DropdownMenu className='drop-menu' top='true'>
                                            <DropdownItem className='drop-item'>
                                                <NavLink to='/gallery' className='font-two py-2'><span className='fa fa-chevron-right mr-2'></span>All works</NavLink>
                                            </DropdownItem>
                                            <DropdownItem className='drop-item'>
                                                <NavLink to='/gallery/available' className='font-two py-2'><span className='fa fa-chevron-right mr-2'></span>Available</NavLink>
                                            </DropdownItem>
                                            <DropdownItem className='drop-item'>
                                                <NavLink to='/gallery/sold' className='font-two py-2'><span className='fa fa-chevron-right mr-2'></span>Sold</NavLink>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <NavItem>
                                        {!CartLogoAnimation ? (
                                        <NavLink className='nav-link mx-4 px-4 py-2' to='/about'>
                                            <span className='font-serif font-semibold text-white'>About</span>
                                        </NavLink>
                                        ) : (
                                        <NavLink className='nav-link mx-4 px-4 py-2 mb-2' to='/about'>
                                            <span className='font-serif font-semibold text-white'>About</span>
                                        </NavLink>
                                        )}
                                    </NavItem>
                                    <NavItem>
                                        {!CartLogoAnimation ? (
                                        <NavLink className='nav-link mx-4 px-4 py-2' to='/contact'>
                                            <span className='font-serif font-semibold text-white'>Contact <span className='fa fa-comments fa-lg'></span></span>
                                        </NavLink>
                                        ) : (
                                            <NavLink className='nav-link mx-4 px-4 py-2 mb-2' to='/contact'>
                                        <span className='font-serif font-semibold text-white'>Contact <span className='fa fa-comments fa-lg'></span></span>
                                        </NavLink>
                                        )}
                                    </NavItem>
                                    <CartLogoAnimation cartItems={this.props.cartItems} removeFromCart={this.props.removeFromCart} clearCart={this.props.clearCart} />
                                </Nav>
        
                        
                            </Collapse>
                        </div>
                    </Navbar>
                </motion.nav>
            </>
        );
    }
}

export default Header;