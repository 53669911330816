//importing main links
import React, { Component } from 'react';
import { Card, CardBody, FormGroup, Label, Form, Input } from 'reactstrap';
import { FadeTransform} from 'react-animation-components';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import { AvailableWorks } from '../functionalComponents/functionalComponents';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { footerVariants, fadeIn, staggerContainer } from '../utils/motion';
import styles from '../styles';

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_USER_ID;

//----MAIN CONTACT PAGE
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_name: '',
            user_email: '',
        };
    }

    render() {
        /* handling contact form submission */
        const handleOnSubmit = (e) => {
            e.preventDefault();
            emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
              .then((result) => {
                console.log(result.text);
                Swal.fire({
                  icon: 'success',
                  title: 'Message Sent Successfully'
                })
              }, (error) => {
                console.log(error.text);
                Swal.fire({
                  icon: 'error',
                  title: 'Ooops, something went wrong',
                  text: error.text,
                })
              });
            e.target.reset()
        };
        /* Contact page form */
        const RenderContactForm = () => {
            return (
                    <div>
                        <FadeTransform in
                        transformProps={{
                        exitTransform: 'scale(0.3) translateY(-20%)'
                        }}>
                        <Card className='bg-[#627f9293] border-[1px] border-white rounded-xl font-latto'>
                            <div className='row'>
                                <div className='col-12 flex justify-center pt-4'>
                                    <img src='/assets/images/message.svg' className='messageImg w-[60px] h-[60px]' alt='cart' />
                                </div>
                            </div>
                            <CardBody>
                                <Form className='p-4' onSubmit={handleOnSubmit}>
                                    <FormGroup>
                                        <Label className='text-white' htmlFor='user_email'><span className='fa fa-envelope mr-2'></span><b>Email<span className='text-danger'> *</span></b></Label>
                                        <Input
                                        className='pay-input py-4'
                                        type='email'
                                        id='user_email'
                                        innerRef={(input) => this.user_email = input}
                                        name='user_email'
                                        placeholder='@email.com'
                                        required
                                        />
                                        </FormGroup>
                                    <FormGroup>
                                        <Label className='text-white' htmlFor='user_name'><span className='fa fa-user mr-2'></span><b>Full Name<span className='text-danger'> *</span></b></Label>
                                        <Input
                                        className='pay-input py-4'
                                        type='text'
                                        id='user_name'
                                        innerRef={(input) => this.user_name = input}
                                        name='user_name'
                                        placeholder='Full Name'
                                        required
                                        />
                                        </FormGroup>
                                    <FormGroup>
                                        <Label className='text-white' htmlFor='user_message'><span className='fa-solid fa-message mr-2'></span><b>Message<span className='text-danger'> *</span></b></Label>
                                        <Input
                                        className='pay-input py-3'
                                        type='textarea'
                                        id='user_message'
                                        name='user_message'
                                        placeholder='Message…'
                                        rows='6'
                                        required
                                        />
                                    </FormGroup>
                                    <button type='submit' className='btn hover:opacity-75 border-white shadow-lg btn-block rounded-xl text-white font-latto font-semibold px-4 py-2 my-4'>Send Message</button>
                                </Form>
                            </CardBody>
                        </Card>
                        </FadeTransform>
                    </div>   
            );
        }


        return (
            <>
                    <div className='bkg-dark'>
                        <div className='container'>
                            <div className='row align-items-center py-5'>
                                <div className='col-12 col-md-6'>
                                    <motion.div
                                        variants={footerVariants}
                                        initial='hidden'
                                        whileInView='show'
                                        viewport={{ once: 'false', amount: 0.25 }}
                                    >
                                        <motion.div
                                            variants={fadeIn('right', 'tween', 0.25, 1)}
                                            initial='hidden'
                                            whileInView='show'
                                        >
                                            <div className='col-12 mt-5'>
                                                <span className='fa fa-arrow-right text-white fa-lg'></span>
                                                <h1 className='font-serif text-[56px] text-white md:text-[78px] drop-shadow-lg pt-5 pb-2'>Have any questions?</h1>
                                                <h4 className='font-serif text-white text-[18px] pt-8'>Whether you want to commission a custom piece of art, collaborate on a project, or just say hello, feel free to reach out to me using the contact form.</h4> 
                                            </div>
                                            <div className={`${styles.xPaddings} ${styles.yPaddings} flex flex-wrap flex-row justify-center`}>
                                                <a href='https://instagram.com/shirleyhackett47' className='border-[2px] border-white rounded-xl shadow-md hover:shadow-lg font-latto py-4 mx-6 my-4'>
                                                    <div className='flex flex-col justify-center items-center'>
                                                        <span className='font-latto font-semibold text-white px-5'>Instagram</span><br />
                                                        <img src='assets/images/instagram.png' className='ml-2 w-[40px] h-[40px]' />
                                                    </div>
                                                </a>
                                                <a href='https://www.facebook.com/shirley.hackett.7' className='border-[2px] border-white rounded-xl shadow-md hover:shadow-lg font-latto py-4 mx-6 my-4'>
                                                    <div className='flex flex-col justify-center items-center'>
                                                        <span className='font-latto font-semibold text-white px-5'>Facebook</span><br/>
                                                        <img src='assets/images/facebook-logo-2019.png' className='ml-2 w-[40px] h-[40px]' />
                                                    </div>
                                                </a>
                                            </div>
                                        </motion.div>
                                    </motion.div>
                                </div>
                                <div className='col-12 col-md-6 pt-5'>
                                    {/* rendering contact form */}
                                    <RenderContactForm />
                                </div>
                            </div>
                        </div>
                    </div>
                <div className='bkg-dark'>
                    <div className={`${styles.paddings} relative z-10`}>
                        <div className='bg-white rounded-xl'>
                            <motion.div
                                variants={staggerContainer}
                                initial='hidden'
                                whileInView='show'
                                viewport={{ once: 'false', amount: 0.25 }}
                                className={`${styles.innerWidth} mx-auto flex flex-col`}
                            >
                                <motion.div
                                    variants={fadeIn('left', 'tween', 0.25, 1)}
                                    initial='hidden'
                                    whileInView='show'
                                    className='text-center pb-5'
                                >
                                    <h1 className='font-serif text-[#627F92] italic font-extrabold tracking-wider text-[36px] md:text-[52px] py-5'><span className=''>Available Works</span></h1>
                                    <h4 className='font-serif  text-[#627F92] uppercase text-[22px] md:text-[28px]'>Check out my range of available works</h4>
                                </motion.div>
                                {/* rendering homepage available */}
                                <div className={`${styles.xPaddings} relative z-10`}>
                                    <div className='row'>
                                        <AvailableWorks 
                                            item={this.props.available1}
                                            isLoading={this.props.isLoading}
                                            errMsg={this.props.errMsg}
                                        />
                                        <AvailableWorks 
                                            item={this.props.available2}
                                            isLoading={this.props.isLoading}
                                            errMsg={this.props.errMsg}
                                        />
                                        <AvailableWorks 
                                            item={this.props.available3}
                                            isLoading={this.props.isLoading}
                                            errMsg={this.props.errMsg}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 text-center py-5'>
                                <Link to='/gallery/available' className='btn border-[2px] border-[#627F92] text-[#627F92] hover:shadow-lg font-lato font-semibold py-2 px-4 my-4'>View More<span className='fa fa-chevron-right ml-2'></span></Link>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Contact;