//importing main links
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Loading } from './LoadingComponent';
import { AddToCart, RenderPaintings } from '../functionalComponents/functionalComponents';

const Gallery = (props, index) => {
   

        const allPaintings = props.paintings.map((painting) => {
            if(painting.isAvailable) {
                return (
                    <div key={painting.id.toString()} className='col-12 col-md-6 col-lg-4 text-center py-3'>
                        <RenderPaintings item={painting} />
                        <AddToCart addToCart={props.addToCart} item={painting} />
                    </div>
                );
            }
            else if(!painting.isAvailable) {
                return (
                    <div key={painting.id.toString()} className='col-12 col-md-4 text-center py-3'>
                        <RenderPaintings item={painting} />
                        <button className='bg-[#627f9294] btn-block rounded-xl text-white font-latto font-semibold px-4 py-2 my-3'>Sold</button>
                    </div>
                );
            }
            else {
                return (
                    <></>
                );
            }
        });

        if(props.isLoading) {
            return (
                <div key={index} className='container'>
                    <div key={props.isLoading} className='row'>
                        <Loading />
                    </div>
                </div>
            );
        }
        else if(props.errMsg) {
            return(
                <div key={index} className='container py-5'>
                    <div className='row d-flex justify-content-center py-5'>
                        <div className='col-12 col-md-8'>
                            <div className='error-card'>
                                <div className='col-12 text-center'>
                                    <img src='/assets/images/error.png' width='220' height='220' alt='error message' />
                                    <h4 className='font-two'>{props.errMsg}</h4>
                                    <h2 className='font-two py-5'>Sorry something went wrong!</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else

        return (
            <>
                {/* service page tabs navigation */}
                <div className='bkg-dark pb-5'>
                    <div className='bg-white container rounded-xl mb-5'>
                    <div className='row d-flex justify-content-center pt-5'>
                        <div className='col-12 text-center'>
                            <h5 className='font-serif text-[#627F92] text-[24px]'>Choose from...</h5>
                         </div>
                    </div>
                    <div className='row d-flex justify-content-center text-center py-5 mx-2'>
                        <div className='col-12 col-md-3 bg-[#627F92] border-[1px] border-none shadow-md rounded-xl hover:shadow-lg py-2 my-2'>
                            <Link to='/gallery/landscape' className='btn'>
                                <span className='font-latto font-semibold text-white text-[22px]'>LANDSCAPE</span>
                            </Link>
                        </div>
                        <div className='col-12 col-md-3 bg-[#627F92] border-[1px] border-none shadow-md rounded-xl hover:shadow-lg py-2 my-2 mx-2'>
                            <Link to='/gallery/seascape' className='btn'>
                                <span className='font-latto font-semibold text-white text-[22px]'>SEASCAPE</span>
                            </Link>
                        </div>
                        <div className='col-12 col-md-3 bg-[#627F92] border-[1px] border-none shadow-md rounded-xl hover:shadow-lg py-2 my-2'>
                            <Link to='/gallery/floral' className='btn'>
                                <span className='font-latto font-semibold text-white text-[22px]'>FLORAL</span>
                            </Link>
                        </div>
                    </div>
                    <div className='container tabs pt-5'>
                        <div className='row'>
                            {allPaintings}
                        </div>
                    </div>
                    </div>
                </div>
            </>
        );
    
}

export default Gallery;